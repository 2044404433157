import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';

import { ReactComponent as FooterMS } from '../icons/footer.svg'

class LandingFooter extends React.Component {


    render() {
        return <Box component="div" sx={{ flexGrow: 1 }}>
            <Box position="static" >
                <Toolbar variant="dense">
                    <Box flex={1} display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center"  sx={{
                            fontWeight: '500',
                            color: '#55566F',
                            fontSize: '1rem'
                        }}>
                            Contact us at: <Link color="inherit" href="mailto:support@mediaspeech.ai" sx={{ display: 'block', marginLeft: '5px' }}>
                                support@mediaspeech.ai
                            </Link>
                        </Box>
                        <Box display="flex">
                            <Typography
                                component="span"
                                variant="h6"
                                sx={{
                                    color: '#55566F',
                                    fontSize: '1rem',
                                    textAlign: "right",

                                    display: { xs: "none", md: "block" },
                                }} >
                                © MediaSpeech. 2023. All rights Reserved ara.
                            </Typography>
                        </Box>
                    </Box>
                </Toolbar>
            </Box>
        </Box>;
    }


}

export default LandingFooter;

