import React from 'react';

class Loader extends React.Component {


  componentDidMount() {
    
  }



  render() {
    return <span className="loader"></span>;
  }


}

export default Loader;

